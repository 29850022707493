<template>
  <div id="companyRegister">
    <div class="header-img">
      <img src="~@/assets/images/guide-company-register.png">
    </div>
    <a-form
      ref="step1Ref"
      class="ant-round-form material-company-register-form"
      layout="vertical"
      :model="modelForm"
      :rules="rules"
      @finish="submit"
    >
      <a-form-item name="countryCode" style="text-align: center" :label="$t('pages_merchant_014')">
        <a-select
          v-model:value="modelForm.countryCode"
          show-search
          size="large"
          option-filter-prop="label"
          :disabled="modelForm.countryCodeDisabled"
          @change="countryChange"
        >
          <a-select-option
            v-for="item in countries"
            :key="item.areaCode"
            :value="item.areaCode"
            :label="language === 'zh' ? item.areaChineseName : item.areaEnglishName"
          >{{ language === 'zh' ? item.areaChineseName : item.areaEnglishName }}</a-select-option>
        </a-select>
        <div v-if="!modelForm.countryCodeDisabled && refused" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.countryCodeMessage }}</span>
        </div>
      </a-form-item>
      <a-form-item name="merchantNameEn" :label="$t('pages_merchant_015')">
        <a-input
          v-model:value="modelForm.merchantNameEn"
          size="large"
          allow-clear
          :disabled="modelForm.merchantNameEnDisabled"
          :placeholder="$t('pages_merchant_015')"
        />
        <div v-if="!modelForm.merchantNameEnDisabled && refused" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.merchantNameEnMessage }}</span>
        </div>
      </a-form-item>
      <a-form-item style="text-align: right">
        <a-button
          html-type="submit"
          type="primary"
          shape="round"
          style="min-width: 138px; margin-top: 24px"
        >{{ $t('pages_merchant_020') }}</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { ref, reactive, toRefs, computed, watchEffect, onUnmounted } from 'vue'
import i18n from '@/locale'
import { _user } from '@/api'
export default {
  name: 'CompanyRegister',
  props: {
    model: {
      type: Object,
      default: () => { return {} }
    },
    pattern: {
      type: Boolean,
      default: () => { return true }
    },
    refused: {
      type: Boolean,
      default: () => { return false }
    },
    countries: {
      type: Array,
      default: () => { return [] }
    }
  },
  setup (props, ctx) {
    let timer = null
    const validatorName = (rule, value) => {
      if (!value) return Promise.reject(i18n.global.t('pages_merchant_015'))
      if (!((/^[\x00-\xff]*$/).test(value))) {
        ctx.emit('update:pattern', false)
        return Promise.reject(i18n.global.t('common_text_046'))
      }
      if (state.exist) {
        state.modelForm.icon = null
        ctx.emit('update:pattern', false)
        return Promise.reject(i18n.global.t('pages_merchant_133'))
      }
      state.exist = false
      state.modelForm.icon = 2
      ctx.emit('update:pattern', true)
      return Promise.resolve()
    }
    const step1Ref = ref(null)
    const state = reactive({
      exist: false,
      language: localStorage.getItem('locale-language') || 'zh',
      rules: {
        merchantNameEn: [{ required: true, validator: validatorName }]
      }
    })

    state.modelForm = computed(() => {
      return props.model
    })

    watchEffect(() => {
      if (props.model.merchantNameEn) {
        clearInterval(timer)
        timer = setInterval(() => {
          clearInterval(timer)
          checkMerchantNameEn(props.model.merchantNameEn)
        }, 300)
      }
    })

    onUnmounted(() => {
      clearInterval(timer)
    })

    const countryChange = () => {
      ctx.emit('update')
    }

    const checkMerchantNameEn = async (merchantEn) => {
      if (!merchantEn || props.refused) return
      const res = await _user.checkMerchantNameEn({ merchantEn })
      if (res.data.succ) {
        state.exist = res.data.data
        step1Ref.value.validateFields('merchantNameEn')
      }
    }

    const submit = () => {
      ctx.emit('next', '1-2')
    }

    return {
      step1Ref,
      submit,
      countryChange,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#companyRegister {
  .header-img {
    margin-bottom: 36px;
    img {
      width: 200px;
    }
  }
}

</style>

